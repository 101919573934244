<template>
  <div :class="isMobile ? 'informatio-details-mobile' : 'informatio-details'">
    <div class="container">
      <template v-if="!isMobile">
        <el-breadcrumb 
          class="breadcrumb-nav"
          separator-class="el-icon-arrow-right"
          separator="/">
          <el-breadcrumb-item :to="{ path: '/information' }">资讯列表</el-breadcrumb-item>
          <el-breadcrumb-item>资讯详情</el-breadcrumb-item>
        </el-breadcrumb>
      </template>
      <el-card shadow="never">
        <div class="details-header">
          <h1>{{ detailsData.title }}</h1>
          <div class="description">{{ detailsData.description }}</div>
        </div>
        <div class="details-content">
          <div ref="content" class="content"></div>
          <p style="color: #a7a7a7;" class="statement">本文转载自其他网站，不代表开源企业观点和立场。如有内容和图片的著作权异议，请及时联系我们（邮箱：<a href="mailto:gpenhisanewtouch.com">wen.luo@newtouch.com</a> )</p>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { getArticleDetails } from '../../api/acticle'
import { isMobile } from '../../util/common'
export default {
  data: function() {
    return {
      detailsData: {},
      isMobile: false
    };
  },
  mounted() { 
    this.isMobile = isMobile()
    const { id } = this.$route.query
    if (id) { 
      this._getArticleDetails(id)
    }
  },
  methods: {
    _getArticleDetails(id) { 
      getArticleDetails(id).then(res => { 
        if (res.code === 200) { 
          this.detailsData = res.data
          this.$refs.content.innerHTML = res.data.content
        }
      }).catch(err => {
        console.log(err);
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.informatio-details {
  padding: 16px 0;
  /deep/.el-icon-arrow-right::before {
    color: #000 !important;
  }
  .button {
    color: #fff;
    padding: 8px 15px;
    border-radius: 4px;
    background-color: #4850FF;
    cursor: pointer;
  }
  .breadcrumb-nav {
    margin-bottom: 16px;
  }

  .details-header {
    padding-bottom: 20px;
    border-bottom: 1px solid #ebedf0;

    .description {
      color: #8790A4;
      font-size: 16px;
    }

  }

  .details-content {
    font-size: 16px;
  }
}

.informatio-details-mobile {
  padding-top: .88rem;
  .container {
    width: 100%;
  }

  .details-header {
    padding-bottom: .2rem;
    border-bottom: 1px solid #ebedf0;

    .description {
      color: #8790A4;
      font-size: 16px;
    }

  }
  /deep/.details-content {
    font-size: 16px;
    img {
      width: 100% !important;
    }
  }
}
</style>
